import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import OrbImg from "../../static/images/spock/spock.png"
import PageHeader from "../partialPages/PageHeader"

import platformPageData from "../data/platformData.json"
import productData from "../data/productData.js"
import hyperData from "../../static/images/spock/spock_hyperdata.png"
import { Col, Row, Container, Popover, OverlayTrigger } from "react-bootstrap"
const popover = (msg, title, img) => (
  <Popover className="">
    {title ? <Popover.Title> {title}</Popover.Title> : null}
    <Popover.Content>
      {img ? (
        <img
          src={img}
          alt={title || "pop over"}
          style={{ height: "100px", width: "100%", display: "block" }}
        />
      ) : null}
      {msg}
    </Popover.Content>
  </Popover>
)
const overlayDiv = (msg, position, title, img) => (
  <OverlayTrigger trigger="hover" overlay={popover(msg, title, img)}>
    <div
      style={{
        position: "absolute",
        left: `${position.x}%`,
        width: `${position.width}%`,
        height: `${position.height}%`,
        top: `${position.y}%`,
      }}
      //className="bg-dark"
    ></div>
  </OverlayTrigger>
)

const overlays = (
  <div
    style={{ position: "absolute", top: 0, left: 0 }}
    className="w-100 h-100"
    id="governance"
  >
    {overlayDiv(
      "SPOCK can ingest data from various data sources.",
      { x: 4, width: 15, height: 70, y: 7 },
      ""
    )}
    {overlayDiv(
      "Centralized data quality, data governance, platform automation, monitoring, and security for the SPOCK ecosystem with continuous integration and delivery. It enables complete automation of AI and machine learning components that are modularised and pluggable based on industry needs.",
      { x: 23, width: 37, height: 9, y: 7 },
      ""
    )}
    {overlayDiv(
      platformPageData["apps"][1]["description"],
      { x: 29, width: 14, height: 13, y: 22 },
      ""
    )}

    {overlayDiv(
      platformPageData["apps"][0]["description"],
      { x: 29, width: 14, height: 13, y: 36 },
      ""
    )}

    {overlayDiv(
      platformPageData["apps"][3]["description"],
      { x: 29, width: 14, height: 13, y: 50 },
      ""
    )}

    {overlayDiv(
      platformPageData["apps"][2]["description"],
      { x: 29, width: 14, height: 13, y: 64 },
      ""
    )}

    {overlayDiv(
      "Standardised, common, on-demand data ingestion and processing component that can ingest and process different data formats into the centralized SPOCK Data platform. Modularised and pluggable data processing components make it easy to customize the processes based on industry and customer needs. ",
      { x: 23, width: 5, height: 61, y: 17 },
      ""
    )}

    {overlayDiv(
      "Automatically track champion and challenger model performance, with automated alerts and retraining.",
      { x: 44, width: 5, height: 61, y: 17 },
      ""
    )}

    {overlayDiv(
      "Optimize and deploy models across different edge devices.",
      { x: 49.5, width: 5, height: 61, y: 17 },
      ""
    )}

    {overlayDiv(
      "Intuitive and interactive visual reports for actionable insights.",
      { x: 55, width: 5.5, height: 30, y: 17 },
      ""
    )}

    {overlayDiv(
      platformPageData["apps"][5]["description"],
      { x: 55, width: 5.5, height: 30, y: 48 },
      ""
    )}

    {overlayDiv(
      productData["apps"][0]["description"],
      { x: 65, width: 16, height: 16, y: 8 },
      ""
      //productData["apps"][0]["img"]
    )}

    {overlayDiv(
      productData["apps"][1]["description"],
      { x: 82, width: 16, height: 16, y: 8 },
      ""
      // productData["apps"][1]["img"]
    )}

    {overlayDiv(
      productData["apps"][2]["description"],
      { x: 65, width: 16, height: 16, y: 25 },
      ""
      //productData["apps"][2]["img"]
    )}

    {overlayDiv(
      productData["apps"][3]["description"],
      { x: 82, width: 16, height: 16, y: 25 },
      ""
      //productData["apps"][3]["img"]
    )}

    {overlayDiv(
      productData["apps"][4]["description"],
      { x: 65, width: 16, height: 16, y: 42 },
      ""
      //productData["apps"][4]["img"]
    )}

    {overlayDiv(
      productData["apps"][5]["description"],
      { x: 82, width: 16, height: 16, y: 42 },
      ""
      // productData["apps"][5]["img"]
    )}

    {overlayDiv(
      productData["apps"][6]["description"],
      { x: 65, width: 16, height: 16, y: 59 },
      ""
      //productData["apps"][6]["img"]
    )}

    {overlayDiv(
      productData["apps"][7]["description"],
      { x: 82, width: 16, height: 16, y: 59 },
      ""
      //productData["apps"][7]["img"]
    )}
  </div>
)
const spockArchSec = () => (
  <>
    <h2
      className="text-center text-bold text-primary pb-4 mb-0"
      style={{ fontSize: "2.5rem" }}
    >
      SPOCK Architecture
    </h2>
    <div style={{ position: "relative" }} className="w-100 pt-1">
      <img src={hyperData} alt="SPOCK Architecture" className="w-100" />
      {overlays}
    </div>
  </>
)
const featuresSec = platformPageData => (
  <>
    {platformPageData.features ? (
      <div className="px-2 ">
        <Row>
          <Col>
            <h2
              className="text-center text-bold text-primary mb-0"
              style={{ fontSize: "2.5rem" }}
            >
              Features
            </h2>
          </Col>
        </Row>
        <div className="d-none d-lg-block">
          <Row className="pt-4">
            {platformPageData.features.map((feature, index) => {
              return (
                <Col md="6" lg="4" key={index} className="mb-4 feature1 ">
                  <div
                    style={{
                      position: "relative",
                      height: "100%",
                      width: "100%",
                    }}
                    className="py-4 "
                  >
                    <div className="top-left-corner"></div>
                    <div className="bottom-right-corner"></div>

                    <div>
                      <h4 className="text-primary">{feature.name}</h4>
                      <p>{feature.description}</p>
                    </div>
                  </div>
                </Col>
              )
            })}
          </Row>
        </div>
        <div className="d-block d-lg-none">
          <Row className="pt-4">
            {platformPageData.features.map((feature, index) => {
              return (
                <Col md="6" key={index} className="px-2 feature1 ">
                  <div
                    style={{
                      position: "relative",
                      height: "100%",
                      width: "100%",
                    }}
                    className="py-2 "
                  >
                    <div className="top-left-corner"></div>
                    <div className="bottom-right-corner"></div>

                    <div>
                      <h4 className="text-primary">{feature.name}</h4>
                      <p>{feature.description}</p>
                    </div>
                  </div>
                </Col>
              )
            })}
          </Row>
        </div>
      </div>
    ) : null}
  </>
)
const plaftform = () => (
  <Layout pageInfo={{ pageName: "platform" }}>
    <SEO
      title={platformPageData.name}
      description={platformPageData.titleTxt}
    />
    <div className="d-none d-lg-block">
      <PageHeader
        bgImg={OrbImg}
        pgTitle={platformPageData.name}
        pgDesc={platformPageData.description}
        pgDesc2={platformPageData.description_2}
      ></PageHeader>
    </div>
    <div className="d-block d-md-none">
      <PageHeader
        bgImg={OrbImg}
        pgTitle={platformPageData.name}
        pgDesc={platformPageData.description}
        pgDesc2={platformPageData.description_2}
        objectPosition={"center -30px"}
      ></PageHeader>
    </div>
    <div className="d-none d-md-block d-lg-none">
      <PageHeader
        bgImg={OrbImg}
        pgTitle={platformPageData.name}
        pgDesc={platformPageData.description}
        pgDesc2={platformPageData.description_2}
        objectPosition={"center -170px"}
      ></PageHeader>
    </div>
    <div className="container  py-5 d-none d-lg-block">{spockArchSec()}</div>
    <div className="px-4  py-5 d-block d-lg-none">{spockArchSec()}</div>
    <div className="bg-white py-5">
      <Container className="d-none d-lg-block">
        {featuresSec(platformPageData)}
      </Container>
      <div className="px-4 d-block d-lg-none">
        {featuresSec(platformPageData)}
      </div>
    </div>
    {/* <PlatformApps page={platformPageData}></PlatformApps> */}
  </Layout>
)
export default plaftform
